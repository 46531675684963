<template>
  <label>
    <input
      :type="type"
      :value="value.id"
      :checked="isActive"
      class="peer absolute size-0 shrink-0 opacity-0"
      @change="changeValue"
    />
    <span
      :class="[$attrs.class]"
      class="relative block w-full cursor-pointer select-none overflow-hidden rounded-lg border border-primary bg-primary px-2 py-1 transition-all peer-hover:border-primary peer-hover:shadow-secondary peer-focus:shadow-secondary"
    >
      <UiGradientBackground v-if="isActive" severity="primary" />
      <div class="relative flex w-full flex-col">
        <UiDate :value="value.beginsAt" template="d" class="font-semibold" />
        <UiDate :value="value.beginsAt" template="MMMM" class="text-xs font-semibold" />
        <span class="flex items-center gap-1">
          <UiDate :value="value.beginsAt" template="yyyy" class="text-xs text-text-tertiary" />
          <UiDate
            :value="value.beginsAt"
            template="EEEEEE"
            :class="{ 'text-text-primary': isWeekend(value.beginsAt) }"
            class="text-xs font-normal text-text-tertiary"
          />
        </span>
      </div>
    </span>
  </label>
</template>

<script lang="ts" setup>
import { isWeekend } from 'date-fns'
import { computed } from 'vue'
import { UiDate, UiGradientBackground } from '@/6_shared/ui'
import type { Seance } from '../../model'

type PropType = {
  modelValue?: Seance['id']
  value: Seance
  type?: 'checkbox' | 'radio'
}
type EmitType = {
  (e: 'update:modelValue', value: Seance['id'] | undefined): void
}

const props = withDefaults(defineProps<PropType>(), {
  modelValue: undefined,
  type: 'checkbox'
})
const emit = defineEmits<EmitType>()

const isActive = computed(() => props.modelValue === props.value.id)

const changeValue = () => {
  const emitValue =
    props.type === 'checkbox' ? (isActive.value ? undefined : props.value.id) : props.value.id

  emit('update:modelValue', emitValue)
}

defineOptions({
  inheritAttrs: false
})
</script>
