<template>
  <div>
    <header class="sr-only">
      <UiTitle severity="h2"> Галерея </UiTitle>
    </header>
    <template v-if="!loading && value">
      <div class="w-full p-0 lg:max-w-screen-xl">
        <EventGalleryPreview :value="value.gallery" @open="openGallery" />
      </div>
      <UiDialog
        :model-value="showFullGallery"
        class="h-screen p-0"
        overlay-color="transparent"
        @update:model-value="closeGallery"
      >
        <UiButton class="!absolute right-4 top-5 z-10" icon @click="closeGallery">
          <UiIcon name="x-mark" class="size-5" />
        </UiButton>
        <ViewEventGallery
          :value="value.gallery"
          :event-title="value.title"
          :initial-slide="initialSlide"
        />
      </UiDialog>
    </template>
    <template v-else>
      <EventGallerySkeleton />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ViewEventGallery } from '@/4_features/Event'
import { type Event, EventGalleryPreview } from '@/5_entities/Event'
import { useQueryParamsOpenState } from '@/6_shared/composables'
import { UiTitle, UiDialog, UiButton, UiIcon } from '@/6_shared/ui'
import EventGallerySkeleton from './EventGallerySkeleton.vue'

type PropType = {
  value?: Event
  loading?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  loading: false,
  value: undefined
})

const showFullGallery = ref(false)
const initialSlide = ref(0)
const route = useRoute()

const openGallery = (objectId: number) => {
  if (!props.value) return

  showFullGallery.value = true
  initialSlide.value = props.value.gallery.findIndex((item) => item.object.id === objectId)

  setParamInRouteQuery('open')
}
// работа с нативной навигацией
const { setParamInRouteQuery } = useQueryParamsOpenState('gallery', () => {
  if (showFullGallery.value) {
    showFullGallery.value = false
  }

  if (route.query?.gallery) {
    showFullGallery.value = true
  }
})

onMounted(() => {
  if (route.query?.gallery) {
    showFullGallery.value = true
  }
})

const router = useRouter()
const closeGallery = () => {
  showFullGallery.value = false
  router.back()
}
</script>
